import type { OrganizationActivityT } from '@prismo-io/schemas';
import type { ApiClient } from '@prismo-io/schemas';
import type { z } from 'zod';
import { InformationsSchema } from '../informations/schema';
import { UseCaseSchema } from '../use-case';
import { VerificationCodeSchema } from '../verification-code/schema';
import { VolumeSchema } from '../volume';

export const CreationSchema = (
  client: ApiClient,
  useCases: (OrganizationActivityT | 'OTHERS')[]
) =>
  UseCaseSchema()
    .merge(VolumeSchema(useCases))
    .merge(InformationsSchema(client, true))
    .merge(VerificationCodeSchema());

export type CreationSchemaT = z.infer<ReturnType<typeof CreationSchema>>;
