import {
  type ApiClient,
  type OrganizationActivityT,
  ToolsOrganizations,
  type ToolsOrganizationsT,
} from '@prismo-io/schemas';
import { CreationSchema, type CreationSchemaT } from './schema';

export const createOrganization = async (
  client: ApiClient,
  params: CreationSchemaT
): Promise<ToolsOrganizationsT> => {
  const {
    data: { session },
  } = await client.auth.getSession();

  if (!session) {
    throw new Error('You must be connected');
  }

  const args = await CreationSchema(client, params.useCases).parseAsync(params);

  const { data } = await client
    .from('tools_organizations')
    .insert({
      name: args.name,
      email: args.email,
      phone: args.phone,
      activities: args.useCases as OrganizationActivityT[],
      volume: args.talentVolume,
    })
    .select('*')
    .single()
    .throwOnError();

  await client
    .from('users')
    .update({
      job: args.job,
    })
    .eq('id', session.user.id);

  return ToolsOrganizations.parse(data);
};
