'use client';

import { ArrowBack, Replay as ReplayIcon } from '@mui/icons-material';
import { Box, Stack, Step, StepButton, Stepper } from '@mui/material';
import { Button } from '@prismo-io/design-system';
import type { ToolsOrganizationsT } from '@prismo-io/schemas';
import { type FC, useEffect, useMemo } from 'react';
import { Complete } from '../complete';
import { useFormState } from './context';
import { InformationsFormWrapper } from './infromations.wrapper';
import { UseCasesFormWrapper } from './use-case.wrapper';
import { useCreateOrganization } from './use-create-organization';
import { VerificationCodeFormWrapper } from './verification-code.wrapper';
import { VolumeFormWrapper } from './volume.wrapper';

export type Props = {
  isSuperAdmin?: boolean;
  onComplete: (organization: ToolsOrganizationsT) => void;
  onTogglePathProfessional?: () => void;
};

export const CreationForm: FC<Props> = (props) => {
  const { isSuperAdmin = false, onComplete, onTogglePathProfessional } = props;

  const { form, prev, setSelectedIndex } = useFormState();

  const { trigger: createOrganization } = useCreateOrganization();

  const neededSteps = useMemo(() => {
    return isSuperAdmin ? [0, 1, 2] : [0, 1, 2, 3];
  }, [isSuperAdmin]);

  useEffect(() => {
    if (form.selectedIndex === neededSteps.length) {
      const verificationCode = isSuperAdmin
        ? '000000'
        : form.steps.verificationCode.value.verificationCode;
      createOrganization(
        {
          ...form.steps.useCases.value,
          ...form.steps.volume.value,
          ...form.steps.informations.value,
          verificationCode,
        },
        {
          onSuccess: (organization: ToolsOrganizationsT) => {
            onComplete(organization);
          },
          onError: () => {
            prev();
          },
        }
      );
    }
  }, [form.selectedIndex, neededSteps]);

  const onClickTalentButton = () => {
    if (form.selectedIndex === 3) {
      prev();
    }

    if (onTogglePathProfessional) {
      onTogglePathProfessional();
    }
  };

  return (
    <Stack spacing={4}>
      <Stepper nonLinear>
        {neededSteps.map((_, i) => {
          const isCompleted = i < form.selectedIndex;
          const isActive = i === form.selectedIndex;
          const canSelectStep = Object.values(form.steps)
            .slice(0, i)
            .every((step) => step.valid && !step.dirty);
          const isCompleteStep = form.selectedIndex === 4;

          return (
            <Step
              key={_}
              active={isActive}
              completed={isCompleted}
              disabled={!canSelectStep || isCompleteStep}
            >
              <StepButton
                disabled={!canSelectStep || isCompleteStep}
                onClick={() => setSelectedIndex(i)}
              />
            </Step>
          );
        })}
      </Stepper>

      <Box component="div">
        {form.selectedIndex === 0 && <UseCasesFormWrapper />}
        {form.selectedIndex === 1 && <VolumeFormWrapper />}
        {form.selectedIndex === 2 && <InformationsFormWrapper />}
        {!isSuperAdmin && form.selectedIndex === 3 && (
          <VerificationCodeFormWrapper />
        )}
        {form.selectedIndex === neededSteps.length && <Complete />}
      </Box>

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {form.selectedIndex === 0 && <Box component="div" />}
        {form.selectedIndex > 0 && (
          <Button
            onClick={prev}
            variant="text"
            color="gray"
            disabled={form.selectedIndex === neededSteps.length}
          >
            <ArrowBack />
            Précedent
          </Button>
        )}
        {onTogglePathProfessional && (
          <Button
            onClick={onClickTalentButton}
            variant="text"
            color="gray"
            disabled={form.selectedIndex === 4}
          >
            Je suis un talent
            <ReplayIcon />
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
