'use client';

import { useMe } from '@prismo-io/feature-auth/providers/me';
import { type ComponentProps, type FC, useCallback, useMemo } from 'react';
import { VerificationCodeForm } from '../verification-code';
import { useFormState } from './context';

type FormProps = ComponentProps<typeof VerificationCodeForm>;

type Props = Omit<FormProps, 'onSubmit' | 'email' | 'firstName' | 'app'>;

export const VerificationCodeFormWrapper: FC<Props> = (props) => {
  const { setForm, next, form } = useFormState();

  const me = useMe();

  const onSubmit: FormProps['onSubmit'] = useCallback(
    (data) => {
      setForm((prev) => ({
        ...prev,
        steps: {
          ...prev.steps,
          verificationCode: {
            ...prev.steps.verificationCode,
            valid: false,
            dirty: false,
            value: data,
          },
        },
      }));

      return next();
    },
    [setForm, next]
  );

  // useEffect(() => {
  //   setForm((prev) => ({
  //     ...prev,
  //     steps: {
  //       ...prev.steps,
  //       verificationCode: {
  //         ...prev.steps.verificationCode,
  //         dirty: isDirty,
  //       },
  //     },
  //   } as typeof prev));
  // }, [isDirty, setForm]);

  const email = useMemo(() => form.steps.informations.value.email, [form]);

  return (
    <VerificationCodeForm
      {...props}
      firstName={me?.first_name}
      app="TOOLS"
      onSubmit={onSubmit}
      email={email}
    />
  );
};
