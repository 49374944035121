'use client';

import { Trans } from '@lingui/macro';
import { CircularProgress, Stack, Typography } from '@mui/material';
import type { FC } from 'react';

export type CompleteProps = {};

export const Complete: FC<CompleteProps> = (props) => {
  return (
    <Stack spacing={4} alignItems="center">
      <CircularProgress />
      <Typography>
        <Trans>Vos données sont en cours de validation...</Trans>
      </Typography>
    </Stack>
  );
};
