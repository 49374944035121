import { z } from 'zod';
import { OrganizationActivity, OrganizationVolume } from '../enums';
import { Users } from './users';

const InfinitySchema = z
  .literal('Infinity')
  .transform(() => Number.POSITIVE_INFINITY);
const MinusInfinitySchema = z
  .literal('-Infinity')
  .transform(() => Number.NEGATIVE_INFINITY);

const NumberOrInfinity = z.number({ coerce: true });

export const ToolsOrganizationsProductUsages = z.object({
  admins_count: NumberOrInfinity,
  admins_limit: NumberOrInfinity,
  admins_rest: NumberOrInfinity,
  toolers_count: NumberOrInfinity,
  toolers_limit: NumberOrInfinity,
  toolers_rest: NumberOrInfinity,
  talents_active: NumberOrInfinity,
  talents_accepted: NumberOrInfinity,
  talents_archived: NumberOrInfinity,
  invites_pending: NumberOrInfinity,
  invites_refused: NumberOrInfinity,
  invites_canceled: NumberOrInfinity,
  invites_deleted: NumberOrInfinity,
  invites_used: NumberOrInfinity,
  invites_limit: NumberOrInfinity,
  invites_rest: NumberOrInfinity,
  projects_count: NumberOrInfinity,
  projects_limit: NumberOrInfinity,
  projects_rest: NumberOrInfinity,
  tags_count: NumberOrInfinity,
  tags_limit: NumberOrInfinity,
  tags_rest: NumberOrInfinity,
  diagnostic_limit: NumberOrInfinity,

  with_faq_resources_access: z.boolean(),
  with_assistance: z.boolean(),
  with_experience_configuration: z.boolean(),
  with_invite_link: z.boolean(),
  with_expert_view_on_app: z.boolean(),
  with_prismotheque_advanced_filters: z.boolean(),
  with_prismotheque_views: z.boolean(),
  with_expert_historic_on_app: z.boolean(),

  anchor_date: z.date({ coerce: true }).nullable(),
  is_trialing: z.boolean(),
  plan: z.string(),
  recurring: z.enum(['year', 'month']).nullish().default('year'),
});

export type ToolsOrganizationsProductUsagesT = z.infer<
  typeof ToolsOrganizationsProductUsages
>;

export const ToolsOrganizationsDashboardStats = z.object({
  admins_count: NumberOrInfinity,
  admins_limit: NumberOrInfinity,
  toolers_count: NumberOrInfinity,
  toolers_limit: NumberOrInfinity,
  projects_count: NumberOrInfinity,
  projects_limit: NumberOrInfinity,
  tags_count: NumberOrInfinity,
  tags_limit: NumberOrInfinity,
  invites_used: NumberOrInfinity,
  invites_limit: NumberOrInfinity,
  invites_rest: NumberOrInfinity,
  invites_pending: NumberOrInfinity,
  talents_accepted: NumberOrInfinity,
  talents_archived: NumberOrInfinity,
  anchor_date: z.date({ coerce: true }).nullable(),
  is_trialing: z.boolean(),
  plan: z.string(),
  crt_invites_accepted: NumberOrInfinity,
  crt_invites_pending: NumberOrInfinity,
  crt_invites_refused: NumberOrInfinity,
  crt_invites_canceled: NumberOrInfinity,
  crt_invites_deleted: NumberOrInfinity,
  crt_invites_archived: NumberOrInfinity,
  trial_start: z.date({ coerce: true }).nullable(),
  trial_end: z.date({ coerce: true }).nullable(),
});

export type ToolsOrganizationsDashboardStatsT = z.infer<
  typeof ToolsOrganizationsDashboardStats
>;

export const ToolsOrganizations = z.object({
  id: z.string().uuid(),
  old_id: z.string().nullable().optional(),
  customer_id: z.string().nullable(),
  email: z.string().email(),
  name: z.string(),
  phone: z.string().nullable(),
  url: z.string(),
  activities: OrganizationActivity.array().nullable(),
  volume: OrganizationVolume.nullable(),
  auto_archived_enable: z.boolean(),
  logo: z.string().nullable(),
  cover: z.string().nullable(),
  tagline: z.string().nullable(),
  website: z.string().nullable(),
  description: z.string().nullable(),
  facebook: z.string().nullable(),
  linked_in: z.string().nullable(),
  twitter: z.string().nullable(),
  instagram: z.string().nullable(),
  config_id: z.string().uuid(),
  year_of_creation: z.number().int().nullable(),
  with_diagnostic: z.boolean(),
  product_usages: ToolsOrganizationsProductUsages.optional(),
  dashboard_stats: ToolsOrganizationsDashboardStats.optional(),
  ai_tokens_used: NumberOrInfinity,
  created_by_id: Users.shape.id.nullable(),
  created_at: z.date({ coerce: true }),
  updated_at: z.date({ coerce: true }),
});

export type ToolsOrganizationsT = z.infer<typeof ToolsOrganizations>;

export const ToolsOrganizationsInsert = ToolsOrganizations.pick({
  name: true,
  email: true,
  phone: true,
  activities: true,
  // type: true,
  volume: true,
}).required();

export type ToolsOrganizationsInsertT = z.infer<
  typeof ToolsOrganizationsInsert
>;

export const ToolsOrganizationsUpdate = ToolsOrganizations.partial().omit({
  id: true,
  product_usages: true,
});

export type ToolsOrganizationsUpdateT = z.infer<
  typeof ToolsOrganizationsUpdate
>;
