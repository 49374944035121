'use client';

import { useApi } from '@prismo-io/core';
import { useAuthStateChange } from '@prismo-io/feature-auth';
import { Users, type UsersT } from '@prismo-io/schemas';
import { useUsermaven } from '@prismo-io/tracking';
import {
  type FC,
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import useSWR, { useSWRConfig } from 'swr';

type MeContextT = {
  me: UsersT | null;
};

export const MeContext = createContext<MeContextT | undefined>(undefined);

type Props = {
  me: UsersT | null;
};

export const MeProvider: FC<PropsWithChildren<Props>> = (props) => {
  const { children } = props;

  const usermaven = useUsermaven();

  const [me, setMe] = useState<UsersT | null>(props.me);

  useEffect(() => {
    if (me && usermaven) {
      usermaven.id({
        id: me.id,
        email: me.email,
        first_name: me.first_name,
        last_name: me.last_name,
        created_at: new Date(me.created_at).toISOString(),
        custom: {
          old_id: me.old_id ?? null,
          community: me.community_code,
          url: me.url,
          has_aura: me.has_aura,
          has_helio: me.has_helio,
          has_quark: me.has_quark,
        },
      });
    }
  }, [me, usermaven]);

  const { client } = useApi();

  const { mutate } = useSWRConfig();

  useSWR(
    () => ['me'],
    async () => {
      const {
        data: { session },
      } = await client.auth.getSession();

      if (!session) {
        return null;
      }

      const { data: user, error } = await client
        .from('users')
        .select('*, full_name, initials, avatar')
        .eq('id', session.user.id)
        .single();

      if (error) {
        return null;
      }

      return Users.parse(user);
    },
    {
      onSuccess: (data) => {
        setMe(data);
      },
      revalidateOnMount: false,
      revalidateOnFocus: false,
      fallbackData: props.me,
    }
  );

  useAuthStateChange((event) => {
    if (event === 'SIGNED_IN') {
      mutate(['me'], undefined, { revalidate: true });
    } else if (event === 'SIGNED_OUT') {
      setMe(null);
    }
  });

  return <MeContext.Provider value={{ me }}>{children}</MeContext.Provider>;
};

export const useMe = () => {
  const ctx = useContext(MeContext);

  if (!ctx) {
    throw new Error('useMe() must be used inside <MeProvider />');
  }

  return ctx.me;
};
